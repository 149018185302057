<template>
  <div style="width: 100%; border: 1px solid #ccc;">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
      mode="default"
    />
    <Editor
      :style="{ height: `${editorAreaHeightStr}` }"
      :defaultConfig="defaultEditConfig"
      mode="default"
      :modelValue="htmlValue"
      @update:modelValue="emit('update:htmlValue', $event)"
      @onCreated="handleCreated"
      @onChange="handleContentChange"

      @customPaste="handlePaste"
    />
  </div>
</template>

<script setup>
  import '@wangeditor/editor/dist/css/style.css';
  import { ref, shallowRef, computed, onMounted, onBeforeUnmount, nextTick } from 'vue';
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue';

  import { getExtWithDotFromFileName, getFileUniqueStr, generateItemKeyIndex } from '@/utils/utils.js';

  const emit = defineEmits(['update:htmlValue', 'pic-select', 'img-paste', 'rtf-paste']);
  const props = defineProps({
    toolbarConfig: {
      type: Object,
      default: {
        excludeKeys: ['group-video', 'emotion']
      }
    },
    editorConfig: {
      type: Object,
      default: null
    },
    editorAreaHeightStr: {
      type: String,
      default: '200px'
    },
    htmlValue: {
      type: String,
      default: '<p><br></p>'
    },

    pasteRTFImg: {
      type: Boolean,
      default: false
    }
  });

  const editorRef = shallowRef(null);
  const defaultEditConfig = computed(() => {
    return props.editorConfig || {
      placeholder: '请输入内容...',
      MENU_CONF: {
        uploadImage: {
          customBrowseAndUpload(insertFn) {
            // insertFn(url, alt, href)
            currentInsertFn = insertFn;
            emit('pic-select');
          },

          server: '/api/xxx',
          customUpload(file, insertFn) {
            if (!file) {
              return;
            }
            currentInsertFn = insertFn;
            emit('img-paste', {
              ext: getExtWithDotFromFileName(file.name),
              file,
              fileType: 1,
              fileUniqueStr: getFileUniqueStr(file),
              fileUrl: '',
              generalFileUpload: false,
              id: '',
              name: file.name,
              saved: false,
              savedId: undefined,
              size: file.size,
              upComplete: false,
              upPercentage: 0,
              videoId: ''
            });
          }
        }
      }
    };
  });

  function handleCreated(editor) {
    editorRef.value = editor;
  }
  function handleContentChange(editor) {
    // console.log(editor.getText());
  }

  function findAllImgSrcsFromHtml(htmlData) {

    let imgReg = /<img.*?(?:>|\/>)/gi;
    let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;

    let arr = htmlData.match(imgReg);
    if (!arr || (Array.isArray(arr) && !arr.length)) {
        return false;
    }


    let srcArr = [];
    for (let i = 0; i < arr.length; i++) {
        let src = arr[i].match(srcReg);
        srcArr.push(src[1]);
    }

    return srcArr;
  }

  function extractImageDataFromRtf(rtfData) {
    if (!rtfData) {
        return [];
    }

    const regexPictureHeader = /{\\pict[\s\S]+?({\\\*\\blipuid\s?[\da-fA-F]+)[\s}]*/
    const regexPicture = new RegExp('(?:(' + regexPictureHeader.source + '))([\\da-fA-F\\s]+)\\}', 'g');
    const images = rtfData.match(regexPicture);
    const result = [];

    if (images) {
      for (const image of images) {
        let imageType = false;

        if (image.includes('\\pngblip')) {
          imageType = 'image/png';
        } else if (image.includes('\\jpegblip')) {
          imageType = 'image/jpeg';
        }

        if (imageType) {
          result.push({
            hex: image.replace(regexPictureHeader, '').replace(/[^\da-fA-F]/g, ''),
            type: imageType
          });
        }
      }
    }

    return result;
  }

  function replaceImagesFileSourceWithInlineRepresentation(htmlData, imageSrcs, imagesHexSources, isBase64Data = true) {
    if (imageSrcs.length < imagesHexSources.length) {
        imagesHexSources = imagesHexSources.slice(imagesHexSources.length - imageSrcs.length);
    }
    for (let i = 0; i < imageSrcs.length; i++) {
        const newSrc = isBase64Data ?
            `data:${imagesHexSources[i].type};base64,${_convertHexToBase64(imagesHexSources[i].hex)}` :
            imagesHexSources[i];

        htmlData = htmlData.replace(imageSrcs[i], newSrc);
    }

    return htmlData;
  }

  function changeBase64ToBlob(base64, name) {
    let base64Arr = base64.split(',');
    let imgType = '';
    let base64String = '';
    if (base64Arr.length > 1) {
      base64String = base64Arr[1];
      imgType = base64Arr[0].substring(base64Arr[0].indexOf(':') + 1, base64Arr[0].indexOf(';')); // 获取图片类型
    }
    let bytes = atob(base64String);
    let bytesCode = new ArrayBuffer(bytes.length);
    let byteArray = new Uint8Array(bytesCode); 
    for (let i = 0; i < bytes.length; i++) {
      byteArray[i] = bytes.charCodeAt(i);
    }
    let blobData = new Blob([bytesCode], {type: imgType});
    let imgSuffix = '.' + imgType.split('/')[1];
    let imageFile = new File([blobData], name + imgSuffix);
    return imageFile;
  }
  function _convertHexToBase64(hexString) {
    return btoa(hexString.match(/\w{2}/g).map(char => {
        return String.fromCharCode(parseInt(char, 16));
    }).join(''));
  }
  function handlePaste(editor, event) {
    let html = event.clipboardData.getData('text/html');

    const rtf = event.clipboardData.getData('text/rtf');

    if (html && rtf) {
        if (!props.pasteRTFImg) {
          return true;
        }
        html = html.replace(/text\-indent:\-(.*?)pt/gi, '')

        const imgSrcs = findAllImgSrcsFromHtml(html);

        if (imgSrcs && Array.isArray(imgSrcs) && imgSrcs.length) {
            const rtfImageData = extractImageDataFromRtf(rtf);

            if (rtfImageData.length) {
                const fileList = rtfImageData.map(({ type, hex }, index) => {
                  const base64Str = `data:${type};base64,${_convertHexToBase64(hex)}`;
                  const file = changeBase64ToBlob(base64Str, generateItemKeyIndex());
                  return {
                    ext: getExtWithDotFromFileName(file.name),
                    file,
                    fileType: 1,
                    fileUniqueStr: getFileUniqueStr(file),
                    fileUrl: '',
                    generalFileUpload: false,
                    id: '',
                    name: file.name,
                    saved: false,
                    savedId: undefined,
                    size: file.size,
                    upComplete: false,
                    upPercentage: 0,
                    videoId: ''
                  }
                });
                emit('rtf-paste', fileList);
                pasteRTFCb = imgURLList => {
                  html = replaceImagesFileSourceWithInlineRepresentation(html, imgSrcs, imgURLList, false);
                  editor.dangerouslyInsertHtml(html);

                  html = null;
                  editor = null;
                }
            } else {
              editor.dangerouslyInsertHtml(html);
            }
        } else {
          editor.dangerouslyInsertHtml(html);
        }
        event.preventDefault();
        return false;
    } else {
        return true;
    }
  }

  onMounted(() => {
    
  });
  onBeforeUnmount(() => {
    const editor = editorRef.value;
    if (editor) {
      editor.destroy();
    }
  });

  let currentInsertFn = null;
  let pasteRTFCb = () => {};
  defineExpose({
    insertAPic(picURL) {
      if (currentInsertFn) {
        if (Array.isArray(picURL)) {
          picURL.forEach(currentInsertFn);
        } else {
          currentInsertFn(picURL);
        }
        currentInsertFn = null;
      }
    },
    pasteRTFFn(imgURLList) {
      pasteRTFCb(imgURLList);
    }
  });
</script>